import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Login from "../components/forms/Login";
import Signup from "../components/forms/Signup";
import SubmitEmail from "../components/forms/SubmitEmail";
import { useUser } from "../hooks/user";
import ArtisButton from "../components/buttons/button";
import { AuthService } from "../api";
import WorkService from "../api/service";
import useCheckMobileScreen from "../hooks/isMobile";
import UserService from "../api/auth";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .required("Email or username is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const signUpValidationSchema = yup.object({
  name: yup.string("Enter your name").required("Name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

export default function SignIn() {
  const { user, login } = useUser();
  const isMobile = useCheckMobileScreen(600);
  const history = useHistory();
  const location = useLocation();

  const [artisCode, setArtisCode] = useState("");

  const [, setSuccess] = useState(false);
  const [, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [artisCodeError, setArtisCodeError] = useState("");

  const [, setIsMissmatch] = useState(false);
  const [submitEmail, setSubmitEmail] = useState("");
  const [openPage, setOpenPage] = useState("");
  const [hasSenderId, setHasSenderId] = useState(false);
  const [hasTransferCode, setHasTransferCode] = useState(false);

  const [senderId, setSenderId] = useState("");
  const [transfer_code, setTransferCode] = useState("");
  const [enableSignUp, setEnableSignUp] = useState(false);

  const [invitedByName, setInvitedByName] = useState("an Artis User");
  const [invitedByTransferCode, setInvitedByTransferCode] = useState(null);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setSubmitEmail(submitEmail);
    setOpenPage("email");

    const response = await AuthService.submitEmail({ email: submitEmail });

    if (response.result) {
      setSubmitEmail("");
      toast.success("Email submitted successfully!");
    } else {
      setSubmitEmail("");
      toast.error("Invalid email!");
    }
  };

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      setSuccess(true);

      const loginAttempt = await login(
        values.email.toLowerCase(),
        values.password
      );
      setIsLoading(false);
      setSuccess(false);

      if (loginAttempt !== "Success") {
        formik.setFieldValue("password", "");
        setError("Invalid password or email!");
      }
    },
  });

  const signupFormik = useFormik({
    initialValues: { name: "", email: "" },
    validationSchema: signUpValidationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      setSuccess(true);

      const identifier = invitedByTransferCode?.length > 0?invitedByTransferCode[0]?.holder_id:senderId;

      // Store the transfer code in localStorage if it exists
      const transferCode = invitedByTransferCode?.length > 0 && invitedByTransferCode?.[0]?.transfer_code;
      if (transferCode) {
        localStorage.setItem({"transfer_code" : transferCode});
      }
      const loginAttempt = await UserService.fameCommunitySignUp({
        invitee_name: values.name,
        invitee_email: values.email.toLowerCase(),
        sender_id: identifier,
      });

      if (loginAttempt?.result) {
        window.location.href = loginAttempt?.result?.signup_url;
      }
      else {
        if(loginAttempt.error.response) {
          toast.error(loginAttempt.error.response.data.error + '!')
        }
        else if(loginAttempt.error) {
          toast.error(loginAttempt.error + '!')
        }
        else {
          toast.error("Encountered an unknown error.")
        }
      }
      setIsLoading(false);
      setSuccess(false);
    },
  });

  useEffect(() => {
    document.title = "Login | Artis.app";
  }, []);

  useEffect(() => {
    if (user) {
      const { from } = location.state || { from: { pathname: "/works" } };
      history.push(from); // redirecting to original location
    }
  }, [user, history, location.state]);

  useEffect(() => {
    setIsMissmatch("");
  }, [formik.values.password]);

  useEffect(() => {
    setError("");
  }, [formik.values.email]);

  const handleLookup = async () => {
    const lookupAttempt = await WorkService.lookup(artisCode);
    const res = lookupAttempt?.result;
    if (res) {
      if (res.artis_code) history.push(`/works/${res.artis_code}`);
    }
    setArtisCodeError(`Nothing is registered under this code: ${artisCode}`);
  };

  useEffect(() => {
    if (hasSenderId) {
      setOpenPage("login");
    }
  }, [hasSenderId]);

  useEffect(() => {
    if (hasTransferCode) {
      setOpenPage("login");
    }
  }, [hasTransferCode]);


  useEffect(() => {
    const getSenderId = async () => {
      // get sender_id from the link
      const urlParams = new URLSearchParams(window.location.search);
      const senderId = urlParams.get("sender_id");
     if(senderId){
       const res = await WorkService.getUserProfile({
         sender_id: senderId,
       })
      if (res?.result) {
        setInvitedByName(res?.result?.profile?.first_name + " " + res?.result?.profile?.last_name)
      }
      else { window.location.href = '/login';}
      if (senderId) {
        setHasSenderId(true);
        setSenderId(senderId);
        setEnableSignUp(true);
      }
    }
  }
    getSenderId();
  }, []);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const transferCode = urlParams.get("transfer_code");
    // Function to get transfer code details
    const getTransferCode = async () => {
      if (transferCode) {
        const resp = await WorkService.getCertificatesTransfer(transferCode);
        if (resp?.result) {
          setInvitedByTransferCode(resp?.result);
        }
        else { window.location.href = '/login';}
        if(transferCode){
          setHasTransferCode(true);
          setTransferCode(transferCode);
          setEnableSignUp(true);
        }
      }
    };
      getTransferCode();
  }, []);

  return (
    <Container maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {
          ((!hasSenderId && !enableSignUp) || (!hasTransferCode && !enableSignUp))  && (
            <Box>
              <Grid
                sx={{
                  fontFamily: "Bellefair, serif",
                  textAlign: "center",
                }}
                container
              >
                <Grid item>
                  <ArtisButton
                    name="Login/Sign up"
                    onClick={() => setOpenPage(openPage === "login" ? "" : "login")}
                    sx={{
                      fontSize: "1.3em",
                    }}
                    textColor={openPage === "login" ? "gray" : "teal"}
                  />
                </Grid>
                <Grid item>
                  <ArtisButton
                    name="Find Copyright"
                    onClick={() =>
                      setOpenPage(openPage === "artis-code" ? "" : "artis-code")
                    }
                    sx={{
                      fontSize: "1.3em",
                    }}
                    textColor={openPage === "artis-code" ? "gray" : "teal"}
                  />
                </Grid>
              </Grid>
            </Box>
          )
        }
        <Box maxWidth={isMobile ? "95%" : "50%"}>
          <Collapse in={openPage === "login"} timeout="auto" unmountOnExit>
            <Typography color="red">
              {error !== "" ? `${error}` : ""}
            </Typography>
            {
              (hasSenderId && enableSignUp) || (hasTransferCode && enableSignUp) ?
                <Signup
                  formik={signupFormik}
                  setEnableSignUp={setEnableSignUp}
                  invitedByName={invitedByName}
                  setHasSenderId={setHasSenderId}
                  setHasTransferCode={setHasTransferCode}
                  transfer_code={transfer_code}
                  getCertificateData={invitedByTransferCode}
                  // onStateChange={handleChildData}
                />
                :
                <Login
                  formik={formik}
                // hasSenderId={hasSenderId}
                // setEnableSignUp={setEnableSignUp}
                />
            }
            {
              (!hasSenderId && !enableSignUp)  && (
                <SubmitEmail
                  isMobile={isMobile}
                  handleEmailSubmit={handleEmailSubmit}
                  submitEmail={submitEmail}
                  setSubmitEmail={setSubmitEmail}
                  setError={setError}
                />
              )
            }
          </Collapse>
        </Box>
        <Box maxWidth={isMobile ? "95%" : "55%"}>
          <Collapse in={openPage === "artis-code"} timeout="auto" unmountOnExit>
            <Box>
              <br />
              <p class="center">Find a copyrighted work by its Artis Code</p>
              <br />
              <Typography color="red">
                {artisCodeError !== "" ? `${artisCodeError}` : ""}
              </Typography>
              <TextField
                style={{ textTransform: "none" }}
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="artis_code"
                label="Artis Code"
                type="text"
                id="name"
                value={artisCode}
                onChange={(e) => {
                  setArtisCode(e.target.value);
                  setArtisCodeError("");
                }}
                autoComplete="off"
                sx={{ mb: 3 }}
                InputProps={{
                  style: {
                    fontSize: "1.3rem",
                  },
                }}
                InputLabelProps={{ required: false, sx: { color: "#9E9E9E" } }}
              />
              <Button
                type="button"
                className="normal"
                fullWidth
                style={{
                  backgroundColor: "transparent",
                  fontSize: "1.5em",
                  color: "teal",
                  textTransform: "none",
                  fontFamily: "Bellefair, serif",
                }}
                variant="text"
                sx={{ mt: -1, mb: 2 }}
                onClick={handleLookup}
              >
                Start Search
              </Button>
            </Box>
          </Collapse>
        </Box>
        <Box maxWidth={isMobile ? "95%" : "55%"} textAlign="center">
        </Box>
        {
          
          <Box>
            <section
              style={{
                paddingTop: "1rem",
                paddingBottom: "1rem",
                textAlign: "justify",
              }}
            >
              <div
                style={{
                  position: 'relative',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'flex',
                  width: '100%',
                  maxWidth: '1080px',
                  marginBottom: '10%',
                  paddingBottom: '0px',
                  WebkitBoxOrient: 'vertical',
                  WebkitBoxDirection: 'normal',
                  flexDirection: 'column',
                  WebkitBoxAlign: 'start',
                  alignItems: 'flex-start',
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitBoxDirection: 'normal',
                    flexDirection: 'column',
                    WebkitBoxPack: 'start',
                    justifyContent: 'flex-start',
                    WebkitBoxAlign: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div className="steps-clone">
                    <div className="step-block">
                      <div className="img-block">
                        <img
                          src="https://uploads-ssl.webflow.com/62064a2c231f73295bd49646/621377ff1d0b2a26c8bc8db7_BW%20crowd.jpg"
                          loading="eager"
                          srcSet="https://uploads-ssl.webflow.com/62064a2c231f73295bd49646/621377ff1d0b2a26c8bc8db7_BW%20crowd-p-500.jpeg 500w, https://uploads-ssl.webflow.com/62064a2c231f73295bd49646/621377ff1d0b2a26c8bc8db7_BW%20crowd-p-1080.jpeg 1080w, https://uploads-ssl.webflow.com/62064a2c231f73295bd49646/621377ff1d0b2a26c8bc8db7_BW%20crowd.jpg 1254w"
                          sizes="(max-width: 479px) 90vw, (max-width: 767px) 70vw, 40vw"
                          alt="Crowd img"
                        />
                      </div>
                      <div className="content-wrap">
                        <div
                          style={{
                            position: 'relative',
                            alignSelf: 'center',
                            backgroundColor: 'transparent',
                            textAlign: 'left',
                          }}
                        >
                          <div className="step">
                            <div className="text-block">
                              <div className="content">
                                <div className="heading">
                                  ARTIS.app<br />
                                </div>
                                <p className="paragraph-16">
                                  Where <strong>CREATIVE PEOPLE</strong><br/><strong>REGISTER</strong>, <strong>PROTECT</strong> and <strong>SHARE</strong> their <strong>CREATIVE WORKS</strong><br/><br/><strong>REGISTER ANY</strong> form of <strong>CREATIVITY</strong><br/>‍<br/>Forever preserving on the blockchain what you created and when.<br/><br/><strong>FOR FREE</strong><br/>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="bg-shadow"></div>
                        </div>
                      </div>
                    </div>
                    <div className="step-block">
                      <div className="scoll-wrap">
                        <div className="scroll-animate"></div>
                        <div className="scroll-base"></div>
                      </div>
                      <div className="content-wrap">
                        <div
                          style={{
                            position: 'relative',
                            alignSelf: 'center',
                            backgroundColor: 'transparent',
                            textAlign: 'left',
                          }}
                        >
                          <div className="step">
                            <div className="text-block">
                              <div className="content">
                                <p className="paragraph-40" style={{ opacity: 1 }}>
                                  Stories, films, books, fine art, photography, crafts, ideas (for stories, commericals, projects, businesses) dance, multimedia, furniture, recipies, remixes, tiktok clips, VR experiences, gaming elements, interior designs, etc, etc, etc.<br/><br/>Each creative work you register is secured by the Ethereum/Polygon blockchains with a unique<strong> PROOF OF REGISTRATION</strong>, <strong>INDISPUTABLE PROOF</strong> of what you registered and when. <br/><br/>Impossible to fake. Easy to verify.<br/>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="bg-shadow"></div>
                        </div>
                      </div>
                      <div className="img-block">
                        <img
                          src="https://uploads-ssl.webflow.com/62064a2c231f73295bd49646/620fb992aab7c6c659d9499e_iphone_artis.jpg"
                          loading="lazy"
                          srcSet="https://uploads-ssl.webflow.com/62064a2c231f73295bd49646/620fb992aab7c6c659d9499e_iphone_artis-p-500.jpeg 500w, https://uploads-ssl.webflow.com/62064a2c231f73295bd49646/620fb992aab7c6c659d9499e_iphone_artis.jpg 656w"
                          sizes="(max-width: 479px) 90vw, (max-width: 767px) 70vw, 40vw"
                          alt="iPhone Artis"
                        />
                      </div>
                    </div>
                    <div className="step-block">
                      <div className="img-block">
                        <img
                          src="https://uploads-ssl.webflow.com/62064a2c231f73295bd49646/620fcc8d962fac20f7e80773_artistearth.jpg"
                          loading="lazy"
                          srcSet="https://uploads-ssl.webflow.com/62064a2c231f73295bd49646/620fcc8d962fac20f7e80773_artistearth-p-500.jpeg 500w, https://uploads-ssl.webflow.com/62064a2c231f73295bd49646/620fcc8d962fac20f7e80773_artistearth.jpg 612w"
                          sizes="(max-width: 479px) 90vw, (max-width: 767px) 70vw, 40vw"
                          alt="Artist Earth"
                        />
                      </div>
                      <div className="content-wrap">
                        <div
                          style={{
                            position: 'relative',
                            alignSelf: 'center',
                            backgroundColor: 'transparent',
                            textAlign: 'left',
                          }}
                        >
                          <div className="step">
                            <div className="text-block">
                              <div className="content">
                                <p className="paragraph-45" style={{ opacity: 1 }}>
                                  <strong>PROTECT</strong> your work against <strong>COPYRIGHT THEFT</strong>, preserving your works&#x27;s value and your <strong>CREATIVE CREDIT</strong>...<br/><br/><strong>...Worldwide!</strong>‍<br/>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="bg-shadow"></div>
                        </div>
                      </div>
                      <div className="scoll-wrap">
                        <div className="scroll-animate"></div>
                        <div className="scroll-base"></div>
                      </div>
                    </div>
                    
                    <div className="step-block">
                      <div className="content-wrap">
                        <div
                          style={{
                            position: 'relative',
                            alignSelf: 'center',
                            backgroundColor: 'transparent',
                            textAlign: 'left',
                          }}
                        >
                          <div className="step">
                            <div className="text-block">
                              <div className="content">
                                
                                <p className="paragraph-23">
                                  Have Artis.app send cease-and-desist or offers-of-settlement emails.<br/><br/>Access other tools to protect and promote your work.<br/><br/><strong>SHARE</strong> your <strong>CREATIONS</strong> with <strong>FRIENDS </strong>and <strong>COLLABORATORS</strong>.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="bg-shadow"></div>
                        </div>
                      </div>
                      <div className="img-block">
                        <img
                          src="https://uploads-ssl.webflow.com/62064a2c231f73295bd49646/620fb117127a2142dbc939c6_framed%20Hands.jpg"
                          loading="lazy"
                          srcSet="https://uploads-ssl.webflow.com/62064a2c231f73295bd49646/620fb117127a2142dbc939c6_framed%20Hands-p-500.jpeg 500w, https://uploads-ssl.webflow.com/62064a2c231f73295bd49646/620fb117127a2142dbc939c6_framed%20Hands-p-1080.jpeg 1080w, https://uploads-ssl.webflow.com/62064a2c231f73295bd49646/620fb117127a2142dbc939c6_framed%20Hands.jpg 1254w"
                          sizes="(max-width: 479px) 90vw, (max-width: 767px) 70vw, 40vw"
                          alt="Framed Hands"
                        />
                      </div>
                      <div className="scoll-wrap">
                        <div className="scroll-animate"></div>
                        <div className="scroll-base"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Box>
        
        }
      </Box>
    </Container>
  );
}
