import React, { useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import Link from "@mui/material/Link";
import ListHeader from "../components/worklist/appbar";
// import QueryFormDialog from "../components/dialogs/filter";
import Work from "../components/work";
import WorksIconView from "../components/worklist/image-list";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import ReactPlayer from "react-player";
import { useTheme } from "@mui/styles";
import WorkRightModal from "../components/tabs/WorkRightModal";
import { WorkService } from "../api";

import { toast } from 'react-toastify';

const LinkStyle = {
  fontSize: "1.2rem",
  border: "none",
  cursor: "pointer",
  m: 2,
  color: "blue",
};

export default function WorkRights(props) {
  const {
    user,
    works,
    setView,
    queryForm,
    isQueryActive,
    setIsQueryActive,
    onPageChange,
    // handleFilter,
    setSort,
    setWorksPerPage,
    // filterOpen,
    setFilterOpen,
    isMobile,
    curPage,
    worksPerPage,
    count,
    view,
    collabs,
    worksIsLoading,
    isCertificateAccept,
    setIsCertificateAccept,
    transferCode,
    getCertificateData,
    setLoading
  } = props;

  const [isLoading, setIsLoading] = React.useState(true);

  const [buttonDisable, setbuttonDisable] = useState(false)
  const theme = useTheme();

  // set works per page to 20, 1 seccond after component mounts
  useEffect(() => {
    // wait 2 seconds
    const timeout = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setWorksPerPage(20);
      setIsLoading(false)
    }
    timeout();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQueryForm = () => {
    setFilterOpen(true);
  }

  const acceptCertificate = async () => {
    try {
      // setIsCertificateAccept(false)
      setLoading(true)
      setbuttonDisable(true)
      // setisAcceptingCertificate(true)
        const response = await WorkService.acceptCertificate({
          transfer_code: transferCode,
        })
        if (response.result) {
          setLoading(false)
          setbuttonDisable(false)
          const successMessage = (
            <div>
              <img alt="Artis.app" src="https://artis.app/images/peace.jpg" />
              <p>
                <strong>Successfully Transfered Certificate</strong>
              </p>
              <br />
            </div>
          );
          toast.success(successMessage);
          // setisAcceptingCertificate(false);
          localStorage.removeItem('transfer_code');
          
          setTimeout(() => {
            window.location.href = '/works';
          }, 3000); 
        // setisAcceptingCertificate(true)
        }
        else {
          setLoading(false)
          setbuttonDisable(false)
          toast.error("Certificate transfer failed!")
        }
        
    } catch (error) {
      setbuttonDisable(false)
        setLoading(false)
        console.error(error)
        toast.error("Certificate transfer failed!")
        // setisAcceptingCertificate(false)
    }
     finally {
      setIsCertificateAccept(false)
      // setisAcceptingCertificate(false)
    }
} 

  return (
   <>
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "",
        mt: "2%",
        overflow: "hidden",
        display: "grid",
        boxShadow: 2,
        backgroundSize: "cover",
        backgroundPosition: "0 400px",
      }}
    >
      <ListHeader
        setViewValue={setView}
        setSortValue={setSort}
        handleQueryForm={handleQueryForm}
        queryForm={queryForm}
        isQueryActive={isQueryActive}
        setIsQueryActive={setIsQueryActive}
        community={false}
      />
      {/* <QueryFormDialog
        queryForm={queryForm}
        open={filterOpen}
        handleClose={() => setFilterOpen(false)}
        handleSubmit={handleFilter}
        suggestedTags={user?.profile?.default_tags}
        setIsQueryActive={setIsQueryActive}
      /> */}
      <Divider />
      <Grid
        direction="column"
        alignItems="stretch"
        container
        sx={{
          mb: 1,
        }}
      >
        {
          count && !isLoading ?
            Object.keys(works).map((key) => (
              <Container key={key}>
                <Divider />
                <Typography fontSize="1.6rem" color="gray" pl={3}>
                  {key}
                </Typography>
                {
                  view === "list" ?
                    works[key].map((work) => (
                      <Work
                        key={work.tx_hash}
                        history={props.history}
                        work={work}
                        profile={user?.profile}
                        isMobile={isMobile}
                        collabs={collabs}
                      />
                    )) :
                    <WorksIconView works={works[key]} profile={user?.profile} />
                }
              </Container>
            )) :
            worksIsLoading ?
              <Container style={{ textAlign: "center", justifyContent: "center", marginTop: 30 }}>
                <CircularProgress thickness={1.8} size="3.5rem" color="success" />
              </Container> :
              <Box
                style={{
                  textAlign: 'center',
                  [theme.breakpoints.down(490)]: {
                    width: window.innerWidth - 120,
                  },
                }}
              >
                <br />
                <br />
                <img
                  alt="Artis.app Earth"
                  src="https://artis.app/images/color_earth.jpg"
                />
                <br />
                <Typography
                  fontSize={isMobile ? "1rem" : "1.2rem"}
                  color="black"
                  m={4}
                  // pl={4}
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    [theme.breakpoints.down(490)]: {
                      width: window.innerWidth - 120,
                    },
                  }}
                >
                  Why Artis.app is the best to protect your art?
                  <br />
                  <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "20px 0px 20px 0px",
                      [theme.breakpoints.down(490)]: {
                        width: window.innerWidth - 120,
                      },
                    }}>
                    <ReactPlayer
                      url="https://player.vimeo.com/video/751697323?h=9ea43f53f7"
                      controls
                      style={{ boxShadow: "1px 2px 5px #AAAAAA" }}
                      width="380px"
                      height="380px"
                      autoplay
                      pip
                    />
                  </div>
                </Typography>
                <br />
                <br />
              </Box>
        }
      </Grid>
      <Grid
        direction={isMobile ? "row" : "column"}
        container
        columns={isMobile ? 1 : 2}
        sx={{
          flexGrow: 1,
          justifyContent: "center",
          display: "flex",
          flexDirection: isMobile ? "row" : "column",
        }}
      >
        <Grid
          item
          xs={isMobile ? 0 : 1}
          textAlign={window.innerWidth ? "center" : "left"}
          sx={{
            alignSelf: isMobile ? "center" : "flex-start",
          }}
          m={isMobile ? 1 : 0}
        >
          <Stack>
            <Pagination
              variant="outlined"
              color="primary"
              size={isMobile ? "small" : "medium"}
              count={Math.ceil(count / worksPerPage)}
              page={curPage}
              onChange={onPageChange}
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={1}
          textAlign={isMobile ? "center" : "left"}
          sx={{
            alignSelf: isMobile ? "center" : "flex-end",
          }}
        >
          <Typography
            component="div"
            color="gray"
            sx={{
              marginLeft: 1,
            }}
          >
            Show:
            <Link
              underline="none"
              sx={LinkStyle}
              onClick={() => setWorksPerPage(20)}
            >
              20
            </Link>
            |
            <Link
              underline="none"
              sx={LinkStyle}
              onClick={() => setWorksPerPage(50)}
            >
              50
            </Link>
            |
            <Link
              underline="none"
              sx={LinkStyle}
              onClick={() => setWorksPerPage(100)}
            >
              100
            </Link>
          </Typography>
        </Grid>
        {
          isCertificateAccept === true && (
            <center>
              <WorkRightModal
                  isVisible={isCertificateAccept}
                  setIsVisible={setIsCertificateAccept}
                  transferCode={transferCode}
                  handleConfirm={acceptCertificate}
                  buttonDisable={buttonDisable}
                  title={
                    getCertificateData?.length > 0 && (
                    <>
                      <strong>{getCertificateData[0]?.holder_name}</strong> wants to transfer to you the 
                      Certificate Of Authenticity for the work called 
                      <strong> {getCertificateData[0]?.work_name}</strong>, created by 
                      <strong> {getCertificateData[0]?.collaborators
                        .map(collaborator => collaborator?.collaborator_name)
                        .join(', ')}</strong>. Do you want to Accept this 
                      Certificate of Authenticity to have your name placed on it as its holder?
                    </>
                      )
                  }
                />
           </center>
          )
        }
      </Grid>
    </Box></>
  );
}
