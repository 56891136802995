import React, { useEffect, useState } from "react";
import CollapsibleBody from "../../../collapsible";
import ApprovalIcon from "@mui/icons-material/Approval";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Slide,
} from "@mui/material";
import ArtisButton from "../../../buttons/button";
import { WorkService } from "../../../../api";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import Skeleton from "@mui/material/Skeleton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Add({ artis_code, allCollabsbyWork, ...other }) {
  const [open, setOpen] = useState(false);
  const [inCertificates, setInCertificates] = useState([]);

  const [certificateLoading, setcertificateLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getCertificates = async () => {
    try {
      const response = await WorkService.getCertificates(artis_code);
      response.result.sort((a, b) => a.cert_num - b.cert_num);
      setInCertificates(response.result);
    } catch (error) {
      console.log("Error fetching collection :", error);
    }
  };

  const addCertificate = async () => {
    setOpen(false);
    setcertificateLoading(true);
    try {
      const response = await WorkService.addCertificate({
        artis_code: artis_code,
      });
      setInCertificates(response.result);
      getCertificates();
      setcertificateLoading(false);
      toast.success("Successfully added Certificate!");
    } catch (error) {
      console.error("Error fetching collections:", error);
      toast.error("Encountered an error while trying to add Certificate!");
      setcertificateLoading(false);
    }
  };

  useEffect(() => {
    getCertificates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <CollapsibleBody
      expanded={other.openId === "add-body"}
      id="add-body"
      title={"Certificates of Authenticity"}
      icon={<ApprovalIcon />}
      handleClick={other.setOpenId}
      mainSx={{}}
    >
      <Box component="div" m={3}>
    
        <Box>
           Artis.app Certificates of Authenticity help maintain the value of your work by proving the work or the copy of your work originated with and was authorized by you.
        </Box>
        <br />
        <Box>
          When you transfer the certificate to a person or business, they become party of your creative community on Artis.app. Additionally, when they hold a certificate, their name appears on the certificate -- thus preventing unauthorised people from claiming ownership.
        </Box>
        <br />
        <Box>
           Certificates of Authenticity can be transferred indefinitely from one person or business to another. All transfers are recorded on the blockchain. Artis.app keeps a history of these transfers (Provenance) so that the chain of people/businesses holding the certificate, traces back to you.
        </Box>
        <br />
        <Box>
          {inCertificates && inCertificates?.length > 0
            ? inCertificates
                .sort((a, b) => a.cert_num - b.cert_num)
                ?.map((certificate) => (
                  <Link
                    to={`/work/${artis_code}?${certificate.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                   <div style={{ 
                      background: "white", 
                      color: "black", 
                      fontWeight: "bold", 
                      borderRadius: "10px", 
                      padding: "15px", 
                      textAlign: "center",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",  // Added drop shadow
                      maxWidth: "400px",
                      margin: "15px auto",
                      border: "2px solid black"  
                    }}>
                    <p style={{ fontSize: ".9em", margin: "10px 0" }}>
                      ARTIS.APP CERTIFICATE {certificate.cert_num} of {inCertificates?.length}
                    </p>
                    <p style={{ 
                    fontSize: "1.2em", 
                    margin: "10px 0", 
                    background: "white",  
                    color: "black",       
                    padding: "10px",      
                    borderRadius: "8px"   
                    }}>
                        Holder: {certificate.holder_name}
                    </p>
                  </div>

               
                  </Link>
                ))
            : null}

          {certificateLoading === true && (
            <Skeleton variant="rectangular" width="100%" height={60} />
          )}
         <br />
        </Box>
         
        {other?.registered_by_id === other?.current_loggedin_id && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <ArtisButton
                name="Add Certificate"
                onClick={handleClickOpen}
                id="id_addgover"
                sx={{
                  fontSize: "1.5rem",
                }}
                disabled={certificateLoading}
              />
            </Grid>
          </Grid>
        )}
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            I <strong>{other.work_creator}</strong> hereby certify by issuing
            this digital Certificate of Authenticity for the work named{" "}
            <strong>{other.title}</strong> and recording the Certificate on the
            blockchain, that this work is an original piece created by{" "}
            {allCollabsbyWork && allCollabsbyWork.length > 0 ? (
              allCollabsbyWork.length === 1 ? (
                <strong>
                  {allCollabsbyWork[0]?.profile?.first_name}{" "}
                  {allCollabsbyWork[0]?.profile?.last_name}
                </strong>
              ) : (
                allCollabsbyWork.map((collaborators, index) =>
                  index === allCollabsbyWork.length - 1 ? (
                    <>
                      and{" "}
                      <strong>
                        {collaborators?.profile?.first_name}{" "}
                        {collaborators?.profile?.last_name}
                      </strong>
                    </>
                  ) : (
                    <>
                      <strong>
                        {collaborators?.profile?.first_name}{" "}
                        {collaborators?.profile?.last_name}
                      </strong>
                      {allCollabsbyWork.length > 2 ? ", " : " "}
                    </>
                  )
                )
              )
            ) : (
              "me"
            )}
            . And I certify that the details contained in its Artis Proof of
            Copyright registration are accurate and true to the best of my
            knowledge.
          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            style={{
              hover: "none",
              textTransform: "none",
              fontSize: "25px",
              fontFamily: "Bellefair, serif",
              color: "teal",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{
              hover: "none",
              textTransform: "none",
              fontSize: "25px",
              fontFamily: "Bellefair, serif",
              color: "teal",
            }}
            onClick={addCertificate}
          >
            Certify
          </Button>
        </DialogActions>
      </Dialog>
    </CollapsibleBody>
  );
}
