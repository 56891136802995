import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TransferByDialog from "./tansfer-by-dialog";
import { WorkService } from "../../api";

export default function TransferDialog(props) {
  const {
    open,
    setOpen,
    inCertificates,
    setInCertificates,
    onTransferRequestFail,
    artis_code,
  } = props;
  const [transferCode, setTransferCode] = React.useState({});
  const [openTransferBy, setOpenTansferBy] = React.useState(false);

  const TransferCertificate = async () => {
    const response = await WorkService.transferCerticate({
      cert_id: inCertificates[0]?.id,
      on_transfer: true,
    });

    if (response.error) {
      onTransferRequestFail(response.error);
      setOpen(false);
    }

    if (response?.result) {
      const certificateDetailsResponse =
        await WorkService.getCertificatesDetails(
          inCertificates[0]?.id,
          artis_code
        );
      setInCertificates(certificateDetailsResponse.result);

      setTransferCode(response.result);
      setOpen(false);
      setOpenTansferBy(true);

      return true;
    }

    return false;
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ padding: "20px 24px 0px 24px" }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: "center" }}
          >
            Are you sure you want to transfer this certificate? Once completed,
            transfers cannot be reversed.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={TransferCertificate}>Yes</Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <TransferByDialog
        openTransferBy={openTransferBy}
        setOpenTansferBy={setOpenTansferBy}
        transferCode={transferCode}
        cert_id={props.cert_id}
        setOpen={setOpen}
      />
    </React.Fragment>
  );
}
