import React, { useState } from "react";
import { Box, Container, Grid, Collapse } from "@mui/material";
import ReactPlayer from "react-player";
import ExpandButton from "../components/work/expand-more";
import HelpIcon from '@mui/icons-material/Help';


export default function Help() {
    const [openId, setOpenId] = useState("");

    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    bgcolor: "background.paper",
                    overflow: "hidden",
                    boxShadow: 1,
                    fontWeight: "bold",
                    mb: 5,
                    pb: 3,
                    mt: 5,
                    pt: 3,
                }}
            >
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 1 ? setOpenId("") : setOpenId(1)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"WHY ARTIS.APP is the best to protect your art?"}
                                    id={1}
                                    handleClick={() => setOpenId(1)}
                                    expanded={openId === 1}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 1}
                        timeout="auto"
                        unmountOnExit
                    >
                        <div 
                            style={{ 
                                display: "flex", 
                                flexDirection: "row", 
                                justifyContent: "center",
                                margin: "20px 0px 20px 0px",
                            }}>
                            <ReactPlayer
                                url="https://player.vimeo.com/video/751697323?h=9ea43f53f7"
                                controls
                                style={{ boxShadow: "1px 2px 5px #AAAAAA" }}
                                width="380px"
                                height="380px"
                                autoplay
                                pip
                            />
                        </div>
                    </Collapse>
                </Box>
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 2 ? setOpenId("") : setOpenId(2)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"What is the COPYRIGHT REGISTRATION EMAIL?"}
                                    id={2}
                                    handleClick={() => setOpenId(2)}
                                    expanded={openId === 2}
                                >
                                     <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 2}
                        timeout="auto"
                        unmountOnExit
                    >
                        <div 
                            style={{ 
                                display: "flex", 
                                flexDirection: "row", 
                                justifyContent: "center",
                                margin: "20px 0px 20px 0px",
                            }}>
                            <ReactPlayer
                                url="https://vimeo.com/753641944/2730593700"
                                controls
                                style={{ boxShadow: "1px 2px 5px #AAAAAA" }}
                                width="380px"
                                height="380px"
                                autoplay
                                pip
                            />
                        </div>
                    </Collapse>
                </Box>
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 3 ? setOpenId("") : setOpenId(3)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How do I SELL MY WORK?"}
                                    id={3}
                                    handleClick={() => setOpenId(3)}
                                    expanded={openId === 3}
                                >
                                       <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 3}
                        timeout="auto"
                        unmountOnExit
                    >
                        <div 
                            style={{ 
                                display: "flex", 
                                flexDirection: "row", 
                                justifyContent: "center",
                                margin: "20px 0px 20px 0px",
                            }}>
                            <ReactPlayer
                                url="https://player.vimeo.com/video/769159375?h=38198d3f37"
                                controls
                                style={{ boxShadow: "1px 2px 5px #AAAAAA" }}
                                width="380px"
                                height="380px"
                                autoplay
                                pip
                            />
                        </div>
                    </Collapse>
                </Box>
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 4 ? setOpenId("") : setOpenId(4)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How do I use SEARCH TAGS?"}
                                    id={4}
                                    handleClick={() => setOpenId(4)}
                                    expanded={openId === 4}
                                >
                                <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 4}
                        timeout="auto"
                        unmountOnExit
                    >
                        <div 
                            style={{ 
                                display: "flex", 
                                flexDirection: "row", 
                                justifyContent: "center",
                                margin: "20px 0px 20px 0px",
                            }}
                        >
                            <ReactPlayer
                                url="https://player.vimeo.com/video/769952597?h=089ae54b99"
                                controls
                                style={{ boxShadow: "1px 2px 5px #AAAAAA" }}
                                width="380px"
                                height="380px"
                                autoplay
                                pip
                            />
                        </div>
                    </Collapse>
                </Box>
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 5 ? setOpenId("") : setOpenId(5)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How do QR CODES work?"}
                                    id={5}
                                    handleClick={() => setOpenId(5)}
                                    expanded={openId === 5}
                                >
                                     <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 5}
                        timeout="auto"
                        unmountOnExit
                    >
                        <div 
                            style={{ 
                                display: "flex", 
                                flexDirection: "row", 
                                justifyContent: "center",
                                margin: "20px 0px 20px 0px",
                            }}>
                            <ReactPlayer
                                url="https://player.vimeo.com/video/755622176?h=1b0c5ce2f4"
                                controls
                                style={{ boxShadow: "1px 2px 5px #AAAAAA" }}
                                width="380px"
                                height="380px"
                                autoplay
                                pip
                            />
                        </div>
                    </Collapse>
                </Box>



                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 6 ? setOpenId("") : setOpenId(6)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How to ADD NEW WORK?"}
                                    id={1}
                                    handleClick={() => setOpenId(6)}
                                    expanded={openId === 6}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 6}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                Super fast and easy. Push the + sign in the bottom right corner of the MAIN PAGE. You will be guided to upload a photo or file containing your work, add the work’s title, and a description of your work. Add the copyright date. Add collaborators if you have them. Last, you can add search tags and any support documentation if you want. This all just takes about one minute. Hit “register on blockchain” and you are done. You will receive email confirmation of your registration. If you added collaborators, they will be sent an email to confirm their participation. It doesn’t get any easier to create proof of authorship of your work. Congratulations!
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>



                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 7 ? setOpenId("") : setOpenId(7)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How to INVITE OTHER PEOPLE to Artis.app?"}
                                    id={1}
                                    handleClick={() => setOpenId(7)}
                                    expanded={openId === 7}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 7}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                    Scroll to the bottom of your MAIN PAGE. Click on “Invite People to Artis.app”. Fill in their Name, Email Address, and a Personal Message. Click “Send Invitation” and you are done. Everyone you’ve invited will appear below in your list of Sent Invitations. It’s that easy. Invite all your creative friends and collaborators today!
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>



         


                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 9 ? setOpenId("") : setOpenId(9)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How to ADD someone to your CREATIVE COMMUNITY?"}
                                    id={1}
                                    handleClick={() => setOpenId(9)}
                                    expanded={openId === 9}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 9}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                    On the MAIN PAGE click the “COMMUNITY” tab. Enter the Name, Email address and a personal message. Click “Send Invitation” and you're done. If your invitation is accepted, they will be added to you creative community.
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>




                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 11 ? setOpenId("") : setOpenId(11)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How to SEND a CEASE & DESIST NOTICE to someone using your registered work without your permission?"}
                                    id={1}
                                    handleClick={() => setOpenId(11)}
                                    expanded={openId === 11}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 11}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                    On the MAIN PAGE, click on the MY WORKS tab. Then click on the work you believe someone has used without your permission. Scroll down and click on “Email Unauthorized Use Warning”. There you can watch a video explaining more about unauthorized use. Fill in the Name of the person or organization you believe is inappropriately using your work, their email address, mailing address if you have it, and upload any proof of their misuse. Make sure to click on the box to send yourself a copy of the misuse warning. Click “Preview Email” to make sure all of the information you entered is correct and then push “Send”, and you are done. 
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>



                            
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 12 ? setOpenId("") : setOpenId(12)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How to EDIT YOUR INFO: your name, contact info, bio, website or photo?"}
                                    id={1}
                                    handleClick={() => setOpenId(12)}
                                    expanded={openId === 12}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 12}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                    On the MAIN PAGE, click on your photo or icon in the top right corner, then click on “Settings”. Here you can alter any of your personal or account information.
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>


                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 13 ? setOpenId("") : setOpenId(13)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How to SEND PROOF of a copyright registration?"}
                                    id={1}
                                    handleClick={() => setOpenId(13)}
                                    expanded={openId === 13}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 13}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                     On the MAIN PAGE click on the MY WORKS tab. Click on the Work you want to send registration proof for. Scroll down to SEND PROOF OF COPYRIGHT REGISTRATION, where you will find complete directions and video about the 3 different ways you can quickly and easily send your proof of registration by Link, Artis Code or QR Code.
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>




                            
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 14 ? setOpenId("") : setOpenId(14)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How to HIDE WORKS works from being seen on the COMMUNITY TIMELINE?"}
                                    id={1}
                                    handleClick={() => setOpenId(14)}
                                    expanded={openId === 14}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 14}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                     It's easy to do that. On the MAIN PAGE click on the MY WORKS tab. Click on the Work you want to hide from being seen in the community time line. Then scroll down to the section that says, Where to Display. Tap on that. You'll find two toggles. One toggle says - In your community's timeline. Switch that toggle to the Off position.
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>

                            


                            
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 15 ? setOpenId("") : setOpenId(15)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How to HIGHLIGHT A WORK you are KNOWN FOR"}
                                    id={1}
                                    handleClick={() => setOpenId(15)}
                                    expanded={openId === 15}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 15}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                     On the MAIN PAGE click on the MY WORKS tab. Click on the Work you want to highlight. Then scroll down to the section that says, Where to Display. Tap on that. You'll find two toggles. The second toggle says - 
In your public bio as a work you are particularly known for.  Switch that toggle to the On position and then whenever Artis.app shows your bio, beneath your bio it will show a section called KNOWN FOR. Under this section, it will show this work. If people click on the name of this work, Artis.app will load up its certificate.
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>





                                    
            </Box>
        </Container>
    )
}
