import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import TransferQr from "./transferQr";
import TransferLink from "./transferLink";
import TransferEmail from "./transferEmail";
import ArtisButton from "../buttons/button";

export default function TransferByDialog(props) {
  const { setOpen, openTransferBy, transferCode, setOpenTansferBy } = props;
  const [openQR, setOpenQR] = React.useState(false);
  const [openLinkModal, setOpenLinkModal] = React.useState(false);
  const [openEmailModal, setOpenEmailModal] = React.useState(false);

  const handleClose = () => {
    setOpenTansferBy(false);
  };

  const handleOpenQR = () => {
    setOpenQR(true);
  };

  const handleOpenLink = () => {
    setOpenLinkModal(true);
  };
  const handleOpenEmail = () => {
    setOpenEmailModal(true);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openTransferBy}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          {"Transfer the certificate by.."}
        </DialogTitle>

        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            sx={{
              bgcolor: "lightseagreen",
              width: "50%",
              color: "white",
              ml: "8px",
              "&:hover": {
                bgcolor: "teal",
                color: "white",
              },
            }}
            onClick={handleOpenQR}
          >
            QR Code
          </Button>

          <Button
            sx={{
              bgcolor: "lightseagreen",
              width: "50%",
              color: "white",
              ml: "8px",
              "&:hover": {
                bgcolor: "teal",
                color: "white",
              },
            }}
            onClick={handleOpenLink}
          >
            Texting Link
          </Button>

          <Button
            sx={{
              bgcolor: "lightseagreen",
              width: "50%",
              color: "white",
              ml: "8px",
              "&:hover": {
                bgcolor: "teal",
                color: "white",
              },
            }}
            onClick={handleOpenEmail}
          >
            Email
          </Button>

          <ArtisButton
            name="Cancel"
            onClick={handleClose}
            id="cancel"
            sx={{
              fontSize: "1.2rem",
            }}
            textColor="red"
          />
        </DialogActions>
      </Dialog>
      <TransferQr
        openQR={openQR}
        setOpenQR={setOpenQR}
        transferCode={transferCode}
        setOpenTansferBy={setOpenTansferBy}
      />
      <TransferLink
        openLinkModal={openLinkModal}
        setOpenLinkModal={setOpenLinkModal}
        transferCode={transferCode}
        setOpenTansferBy={setOpenTansferBy}
        setOpen={setOpen}
      />
      <TransferEmail
        openEmailModal={openEmailModal}
        setOpenEmailModal={setOpenEmailModal}
        transferCode={transferCode}
        setOpenTansferBy={setOpenTansferBy}
        cert_id={props.cert_id}
      />
    </React.Fragment>
  );
}
