import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import PendingIcon from '@mui/icons-material/Pending';

import UserService from "../../api/auth";
import ArtisButton from "../buttons/button";
import Loader from "../buttons/loader";

import FameModal from './FameModal'

import utils from "../../utils";
import { toast } from "react-toastify";

import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import { useUser } from "../../hooks/user";
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@mui/material';

const possibleOrder = ["0", "1", "2", "3"];

const label = {
  inputProps: {
    "aria-label": "Move this account to the top of your public pages.",
  },
};

const successMessage = (name) => (
  <div>
    <img alt="Artis.app" src="https://artis.app/images/peace.jpg" />
    <p>
      <strong>CONGRATS!!!</strong>
    </p>
    <br />
    <p>Invitation sent to </p>
    <p>'{name}'</p>
    <p>for approval!</p>
  </div>
);

const successAcceptMessage = (name) => (
  <div>
    <img alt="Artis.app" src="https://artis.app/images/peace.jpg" />
    <p>
      <strong>CONGRATS!!!</strong>
    </p>
    <br />
    <p>'{name}'</p>
    <p>is now added as </p>
    <p>your community member</p>
  </div>
);

const sendInvitees = async (actualUser, fame, getAllFameConnections) => {
  try {
    await UserService.sendInvitee({
      id: fame.id,
      common_community: `${actualUser.first_name} ${actualUser.last_name}`,
      invitee_name: `${fame.first_name} ${fame.last_name}`,
      invitee_email: fame.email,
      invitee_message: fame.artist_bio,
    });
    if (getAllFameConnections) {
      await getAllFameConnections();
    }
    toast.success(successMessage(`${fame.first_name} ${fame.last_name}`), {
      onClose: () => {
        // Commented out to stop page from going back to /works
        // history.push("/works");
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const acceptInvite = async (fame, getAllFameConnections) => {
  const payload = [];
  try {
    payload.push({ id: fame.id, status: 3 });
    await UserService.updateOrder(payload);
    toast.success(
      successAcceptMessage(`${fame.user.first_name} ${fame.user.last_name}`),
      {
        onClose: () => {},
      }
    );
    if (getAllFameConnections) {
      await getAllFameConnections();
    }
  } catch (error) {
    console.error(error);
  }
};

const deleteInvite = async (fame, getAllFameConnections) => {
  try {
    await UserService.deleteFameConnection(fame.id);
    if (getAllFameConnections) {
      await getAllFameConnections();
    }
  } catch (error) { }
};

const updateOrder = async ({
  fame,
  order,
  allConnections,
  getAllFameConnections,
  setIsLoading,
  setIsExpanded,
}) => {
  const payload = [];
  try {
    if (order === 0) {
      payload.push({ id: fame.id, order });
      await UserService.updateOrder(payload);
    } else {
      let currentOrder = 1;
      payload.push({ id: fame.id, order: currentOrder });
      const filtered = allConnections
        .filter((con) => con.id !== fame.id)
        .filter((t) => t.order !== 0)
        .sort((a, b) => a.order - b.order);
      filtered.forEach((f) => {
        if (f.order !== 0) {
          currentOrder += 1;
          payload.push({ id: f.id, order: currentOrder });
        }
      });
      await UserService.updateOrder(payload);
    }
  } catch (error) {
    console.error(error);
  } finally {
    if (getAllFameConnections) {
      //getAllFameConnections()
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    // setIsExpanded(false)
  }
};

const FameUserItem = ({
  children,
  showOrder,
  allConnections,
  user,
  name,
  is_kyced,
  order,
  maxOrder,
  setMaxOrder,
  onDelete,
  selectedOrderItems,
  setSelectedOrderItems,
  onClick,
  shouldExpand,
  isPending,
  fame,
  getAllFameConnections,
  userProfile, // current loggedIn user
  endorsedBy,
  fromFame,
  showEndorsement,
  totalConnections,
  status,
  sender,
  actualUser,
  fromEditOrDetail
}) => {
  const [isEndorsementChecked, setIsEndorsementChecked] = useState(utils.checkNumberInString(user.endorsed_by, userProfile?.user?.id));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(order !== 0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customerUser,] = useState(user);
  const [isVisibleReject, setIsVisibleReject] = useState(false);


  const setEndorsement = async () => {
    try {
      const response = await UserService.endorse({
        endorsed_to: customerUser.user_id,
        endorsed_by: userProfile?.user?.id,
        is_endorsed: !isEndorsementChecked,
      });

      setIsModalVisible(false);
      if (response.result.success === true) {
        setIsEndorsementChecked(!isEndorsementChecked);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Box
      sx={{
        cursor: "pointer",
        marginBottom: 1,
        marginTop: 1,
        padding: "5px 5px 5px 5px",
        borderRadius: 1,
        borderLeft: 1,
        borderRight: 1,
        borderTop: 1,
        borderBottom: 1,
        borderColor: "#D3D3D3",
      }}
    >
      {/* This shows the users info when the user box is not clicked/expanded */}
      <Box
        sx={{ display: "flex", flexFlow: "row wrap", alignItems: "center" }}
        onClick={() => {
          if (onClick && !isExpanded) {
            onClick();
          }
          if (shouldExpand) {
            setIsExpanded(!isExpanded);
          }
        }}
      >
        {shouldExpand && (
          <>
            {customerUser.photo ? (
              <img
                style={{ marginRight: 15, objectFit: "cover", width: 24 }}
                src={`${customerUser.photo}`}
                alt="profileimg"
              />
            ) : (
              <PersonIcon style={{ marginRight: 15 }} />
            )}
          </>
        )}
        <Typography
          sx={{
            paddingLeft: shouldExpand ? 0 : "25px",
          }}
        >
          {name}
        </Typography>
        {is_kyced && (
          <img
            style={{ height: 10, marginLeft: 2, marginRight: 10 }}
            alt="identity validated"
            src="/images/GoldWreath.jpg"
          />
        )}
        {sender === false && status === 4 && !fromEditOrDetail &&
          <PendingIcon sx={{ color: "teal" }}/>
        }
      </Box>
      {/* This displays the toggle button for endorsing the user */}
      {isExpanded && (
        <>
          <Box
            sx={{
              display: "flex",
              flexFlow: "column wrap",
              alignItems: "flex-start",
              mt: 2,
              ml: 2,
            }}
          >
            {
              is_kyced && userProfile?.user?.is_kyced && fromFame && showEndorsement && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={isEndorsementChecked}
                      sx={utils.switchStyle}
                      onChange={async (e) => {
                        setIsModalVisible(true);
                      }}
                    />
                  }
                  label="Endorse their professional identity"
                  sx={{
                    backgroundColor: "#e8f5e9",
                    paddingRight: "12px",
                    color: "#70768a",
                    margin: "initial",
                    marginBottom: "15px",
                  }}
                />
              )
            }
            <Box>
              <div>
                {customerUser?.photo && (
                  <>
                    <img
                      style={{
                        margin: "0 15px 15px 0",
                        objectFit: "cover",
                        width: 150,
                      }}
                      src={`${customerUser.photo}`}
                      alt="profileimg"
                    />
                  </>
                )}
              </div>
            </Box>

            <Box sx={{ display: "flex", flexFlow: "column wrap" }}>
              {/* {customerUser?.artist_bio && (
                <>
                  <Typography
                    sx={{
                      fontSize: "14.5px",
                      color: "gray",
                      fontStyle: "italic"
                    }}
                  >
                    {customerUser?.artist_bio}
                  </Typography>
                  <br />
                </>
              )} */}
              {customerUser?.artist_website && (
                <>
                  <Typography
                    color="#006cd0"
                    onClick={() => window.open(customerUser?.artist_website, '_blank').focus()}
                  >
                    {customerUser?.artist_website?customerUser.artist_website.split("//")[1] || customerUser.artist_website.split("//")[0]:''}
                  </Typography>
                  <br />
                </>
              )}
            </Box>

            <Box>
              {setSelectedOrderItems && (
                <select
                  style={{ width: 50 }}
                  onChange={setSelectedOrderItems}
                  value={order.toString()}
                  disabled={
                    selectedOrderItems.length === 3 &&
                    !selectedOrderItems.includes(order.toString())
                  }
                >
                  {possibleOrder.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              )}
            </Box>
          </Box>
        </>
      )}
      {isExpanded && (
        <>
          <Box sx={{ paddingBottom: 2, paddingTop: 2 }}>
            {onDelete && (
              <center>
                <div
                  style={{
                    borderTop: "2px solid #D3D3D3 ",
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                ></div>
                <br />
                <br />
                <DeleteIcon sx={{ color: "red" }} onClick={onDelete} />
              </center>
            )}
          </Box>
        </>
      )}
      {isExpanded && is_kyced && showOrder && (
        <>
          <div>
            <center>
              <FormControlLabel
                control={
                  <Switch
                    checked={isChecked}
                    onChange={(e) => {
                      setIsChecked(!isChecked);
                    }}
                    {...label}
                  />
                }
                label="Pin this name to the top of your community list."
                sx={{
                  backgroundColor: "#e8f5e9",
                  paddingRight: "12px",
                  color: "#70768a",
                  margin: "initial",
                  marginBottom: "15px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "column wrap",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                {!isLoading && (
                  <ArtisButton
                    id="submit"
                    onClick={async () => {
                      setIsLoading(true);
                      if (isChecked) {
                        const ord = maxOrder + 1;
                        updateOrder({
                          fame,
                          order: ord,
                          allConnections,
                          getAllFameConnections,
                          setIsLoading,
                          setIsExpanded,
                        });
                        setMaxOrder(ord);
                      } else {
                        updateOrder({
                          fame,
                          order: 0,
                          getAllFameConnections,
                          setIsLoading,
                          setIsExpanded,
                        });
                      }
                    }}
                    name="Update"
                    sx={{
                      color: "blue",
                      fontSize: "1.2rem",
                      mb: 3,
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  />
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Loader isLoading={isLoading} />
                </Box>
              </Box>
            </center>
          </div>
        </>
      )}
      {isExpanded && (
        <>
          {children}
          {sender === false && status === 4 && !fromEditOrDetail && (
            <Box sx={{ display: "flex", flexFlow: "row" }}>
              <ArtisButton
                name="Accept Invitation"
                textColor="green"
                onClick={() => acceptInvite(fame, getAllFameConnections)}
                sx={{
                  fontSize: "1.5rem",
                }}
              />
              <ArtisButton
                name="Decline invitation"
                textColor="red"
                onClick={() => setIsVisibleReject(true)}
                sx={{
                  fontSize: "1.5rem",
                }}
              />
            </Box>
          )}
          {
            <Dialog
              open={isVisibleReject}
              onClose={() => setIsVisibleReject(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
                Are you sure you want to decline {name} community invitation?
              </DialogTitle>
              <DialogActions>
                <ArtisButton
                  name="Yes"
                  onClick={() => {deleteInvite(fame, getAllFameConnections)
                    setIsVisibleReject(false)}}
                  sx={{
                    fontSize: "1.5rem",
                  }}
                />
                <ArtisButton
                  name="No"
                  onClick={() => setIsVisibleReject(false)}
                  sx={{
                    fontSize: "1.5rem",
                  }}
                />
              </DialogActions>
            </Dialog>
          }
        </>
      )}
      {isExpanded && (
        <>
          {(sender === true && status === 4) && !fromEditOrDetail && (
            <Box
                sx={{
                display: 'flex',
                flexFlow: "row wrap",
                backgroundColor: '#e8f5e9',
                padding: "5px",
                margin: "20px 50",
                justifyContent: "center"
                }}
            >
                <Typography sx={{ color: 'teal', fontSize: "18px"}}>Awaiting Invitation Acceptance</Typography>
            </Box>
          )}
        </>
      )}
      <div>
        <center>
          <FameModal
            isVisible={isModalVisible}
            setIsVisible={setIsModalVisible}
            handleConfirm={setEndorsement}
            title={isEndorsementChecked ?
              `Please confirm that you are removing your public endorsement of ${name} professional identity.` :
              `Please confirm that you are publicly endorsing ${name} professional identity.`
            }
            actionButtonText="Confirm"
          />
        </center>
      </div>
    </Box>
  );
};

export const FameUserItemWrapper = ({
  user,
  showOrder = true,
  allConnections,
  name,
  maxOrder,
  setMaxOrder,
  is_kyced,
  order,
  onDelete,
  selectedOrderItems,
  setSelectedOrderItems,
  shouldExpand,
  userProfile,
  isPending = false,
  fame,
  getAllFameConnections,
  fromFame = false,
  endorsedBy,
  totalConnections,
  sender,
  status,
  fromEditOrDetail,
  secondLayer = false,
  actualUser
}) => {
  const [subConnections, setSubConnections] = useState(null);
  const [userId, setUserId] = useState(null);
  // State to store the classification of the user
  const [userClassification,] = useState(user.endorsed_by);
  const [isVisible, setIsVisible] = useState(false);

  const [knownFor, setKnownFor] = useState([]);
  const auth=useUser();

  useEffect(() => {
    if (order > maxOrder) {
      setMaxOrder(order);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user.is_active) return null;

  const shouldShowInvitationButton = (userId) => {
    return !totalConnections?.some(
      (collection) => collection.user.user_id === userId
    );
  };

  const handleInvite = (customerUser) => {
    // setCustomerUser(customerUser);
    setIsVisible(true);
  };

  const handleClick = async () => {
    if (userId === user.user_id) {
      return;
    }
    setUserId(user.user_id);
    try {
      const response = await UserService.getFameConnections(`?id=${user.user_id}`);

      const {profile}=auth.user;
      if (response.result) {
        const verified_connections = [];
        const unverified_connections = [];
        response.result.all_fame.forEach((fame) => {
          const customerUser = user.user_id === fame?.sender[0].user_id ? fame?.invitee[0] : fame?.sender[0];
          // skip adding to the array user
          if (fame?.invitee[0].user_id === profile.user.id || fame?.sender[0].user_id === profile.user.id) {
            return;
          }
          if (customerUser.is_kyced) {
            verified_connections.push({ ...fame, user: customerUser });
          } else {
            unverified_connections.push({ ...fame, user: customerUser });
          }
        });
        setSubConnections({ verified_connections, unverified_connections });

        setKnownFor({
          worksKnownFor: response.result.works_known_for ? response.result.works_known_for : [],
          collaboratorsKnownFor: response.result.collaborators_known_for ? response.result.collaborators_known_for : [],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Separate connections into "Professional Endorsements" and "Creative Community"
  const professionalEndorsements = [];
  const creativeCommunity = [];

  // Function to classify connections based on userClassification
  const classifyConnections = (connections) => {
    connections?.forEach((con) => {
      // Check if user is endorsed based on userClassification
      const endorsedByArray = userClassification ? userClassification.split(',') : [];
      const isEndorsedByUser = endorsedByArray.includes(`${con.user.user_id}`);
      const connectionArray = isEndorsedByUser ? professionalEndorsements : creativeCommunity;

      if (!con.user.is_active || con.status === 4) return
      // Add FameUserItem component to the appropriate array
      if (!secondLayer) {
      connectionArray.push(
        <FameUserItemWrapper
          key={con.id}
          user={con.user}
          name={con.user.first_name && con.user.last_name ? `${con.user.first_name} ${con.user.last_name}` : con.invitee_name}
          order={con.order}
          is_kyced={con.user.is_kyced}
          shouldExpand={(status !== 4)}
          showOrder={false}
          userProfile={userProfile}
          fromFame={fromFame}
          showEndorsement={false}
          endorsedBy={endorsedBy}
          invitation={true}
          totalConnections={totalConnections}
          getAllFameConnections={getAllFameConnections}
          actualUser={user}
          fromEditOrDetail={fromEditOrDetail}
          secondLayer={true}
        />
      );
    } else {
      connectionArray.push(
        <FameUserItem
          key={con.id}
          user={con.user}
          name={con.user.first_name && con.user.last_name ? `${con.user.first_name} ${con.user.last_name}` : con.invitee_name}
          order={con.order}
          is_kyced={con.user.is_kyced}
          shouldExpand={false}
          showOrder={false}
          userProfile={userProfile}
          fromFame={fromFame}
          showEndorsement={false}
          endorsedBy={endorsedBy}
          invitation={true}
          totalConnections={totalConnections}
          getAllFameConnections={getAllFameConnections}
          actualUser={user}
          fromEditOrDetail={fromEditOrDetail}
          secondLayer={true}
        />
      );
    }
    });
  };

  // Classify verified connections
  classifyConnections(subConnections?.verified_connections);
  // Classify unverified connections
  classifyConnections(subConnections?.unverified_connections);

  return (
    <FameUserItem
      user={user}
      name={name}
      order={order}
      maxOrder={maxOrder}
      setMaxOrder={setMaxOrder}
      is_kyced={is_kyced}
      selectedOrderItems={selectedOrderItems}
      onDelete={onDelete}
      setSelectedOrderItems={setSelectedOrderItems}
      onClick={handleClick}
      shouldExpand={shouldExpand}
      isPending={isPending}
      fame={fame}
      getAllFameConnections={getAllFameConnections}
      allConnections={allConnections}
      showOrder={showOrder}
      userProfile={userProfile}
      fromFame={fromFame}
      showEndorsement={true}
      endorsedBy={endorsedBy}
      totalConnections={totalConnections}
      sender={sender}
      status={status}
      actualUser={user}
      fromEditOrDetail={fromEditOrDetail}
    >
      <Box sx={{ marginLeft: { xs: 0, md: 0, lg: 2.5 } }}>
        {/* Known For */}
        {
          (knownFor.worksKnownFor?.length > 0 ||
            knownFor.collaboratorsKnownFor?.length > 0) &&
          <Box
            component="p"
            fontSize="0.8rem"
            display="inline"
            color="gray"
            sx={{ marginLeft: "0px" }}
          >
            KNOWN FOR
          </Box>
        }
        {
          knownFor.worksKnownFor?.length > 0 &&
          knownFor.worksKnownFor?.map((work, index) => {
            return (
              <Box
                sx={{
                  marginBottom: 1,
                  padding: "5px 5px 5px 5px",
                  borderBottom: knownFor.worksKnownFor?.length + knownFor.collaboratorsKnownFor?.length > 1 ? 1 : 0,
                  borderColor: "#D3D3D3",
                  marginTop: index === 0 ? 0 : 1,
                  overflow: "auto",
                  whiteSpace: "nowrap",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexFlow: "row wrap",
                    alignItems: "left"
                  }}
                >
                  <button
                    type="button"
                    style={{
                      background: "none",
                      border: "none",
                      padding: "0",
                      color: "#808080",
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "none",
                        color: "#808080",
                      },
                      "&:focus": {
                        textDecoration: "none",
                        color: "#808080",
                      },
                    }}
                    onClick={() => window.open(`/works/${work.artis_code}`, '_blank')}
                  >
                    <>
                      {work.work_copy_name ? (
                        <img
                          style={{ marginRight: 15, objectFit: "cover", width: 24 }}
                          src={`https://artis-stage-data.s3.amazonaws.com/static/works/images/${utils.formatImageName(work.work_copy_name)}`}
                          alt="profileimg"
                        />
                      ) : (
                        <ArtTrackIcon />
                      )}
                    </>
                    {`${work.title}`}
                  </button>
                </Box>
              </Box>
            )
          })
        }
        {
          knownFor.collaboratorsKnownFor?.length > 0 &&
          knownFor.collaboratorsKnownFor?.map((work, index) => {
            return (
              <Box
                sx={{
                  marginBottom: 1,
                  padding: "5px 5px 5px 5px",
                  borderBottom: knownFor.worksKnownFor?.length + knownFor.collaboratorsKnownFor?.length > 1 ? 1 : 0,
                  borderColor: "#D3D3D3",
                  marginTop: index === 0 && knownFor.worksKnownFor?.length === 0 ? 0 : 1,
                  overflow: "auto",
                  whiteSpace: "nowrap",
                }}
              >
                <Box
                  sx={{ display: "flex", flexFlow: "row wrap", alignItems: "left" }}
                >
                  <button
                    type="button"
                    style={{
                      background: "none",
                      border: "none",
                      padding: "0",
                      color: "#808080",
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "none",
                        color: "#808080",
                      },
                      "&:focus": {
                        textDecoration: "none",
                        color: "#808080",
                      },
                    }}
                    onClick={() => window.open(`/works/${work.work_artis_code}`, '_blank')}
                  >
                    <>
                      {work.work_copy_name ? (
                        <img
                          style={{ marginRight: 15, objectFit: "cover", width: 24 }}
                          src={`https://artis-stage-data.s3.amazonaws.com/static/works/images/${utils.formatImageName(work.work_copy_name)}`}
                          alt="profileimg"
                        />
                      ) : (
                        <ArtTrackIcon />
                      )}
                    </>
                    {`${work.work_title}`}
                  </button>
                </Box>
              </Box>
            )
          })
        }
        {
          (knownFor.worksKnownFor?.length > 0 ||
            knownFor.collaboratorsKnownFor?.length > 0) &&
          <Typography
            fontSize="0.6rem"
            gutterBottom
            width="100%"
            float="right"
            textAlign="right"
          >
            TAP ON WORK TO SEE ITS REGISTRATION
          </Typography>
        }
        {/* Professional Endorsements */}
        {professionalEndorsements.length > 0 && (
          <>
            <Box
              component="p"
              fontSize="0.8rem"
              display="inline"
              color="gray"
              sx={{ marginLeft: "0px" }}
            >
              PROFESSIONALLY ENDORSED BY
            </Box>
            {professionalEndorsements}
          </>
        )}

        {/* Biography */}
        {user?.artist_bio && (
          <Box sx={{ mt: 2, mb: 2 }}>
            <Box
              component="p"
              fontSize="0.8rem"
              display="inline"
              color="gray"
            >
              BIOGRAPHY
            </Box>
            <Box
              sx={{
                fontSize: "14.5px",
                color: "gray",
                
              }}
            >
              {user?.artist_bio}
            </Box>
          </Box>
        )}

        {/* Creative Community */}
        {creativeCommunity.length > 0 && (
          <>
            <Box
              component="p"
              fontSize="0.8rem"
              display="inline"
              color="gray"
              sx={{ marginLeft: "0px" }}
            >
              CREATIVE COMMUNITY
            </Box>
            {creativeCommunity}
          </>
        )}

        {shouldShowInvitationButton(user?.user_id) && !fromEditOrDetail && (
          <ArtisButton
            name={'Invite '+name+' to join your community'}
            textColor="teal"
            onClick={() => handleInvite(user)}
            sx={{
              fontSize: "1.5rem",
              backgroundColor: "teal"
            }}
          />
        )}

        <Dialog
          open={isVisible}
          onClose={() => setIsVisible(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
            Are you sure you want to invite {name}?
          </DialogTitle>
          <DialogActions>
            <ArtisButton
              name="Yes"
              onClick={() => {sendInvitees(actualUser, user, getAllFameConnections)
                setIsVisible(false)}}
              sx={{
                fontSize: "1.5rem",
              }}
            />
            <ArtisButton
              name="No"
              onClick={() => setIsVisible(false)}
              sx={{
                fontSize: "1.5rem",
              }}
            />
          </DialogActions>
        </Dialog>
      </Box>
    </FameUserItem>
  );
};
