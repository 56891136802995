import { Box, Grid } from "@mui/material";
import CollapsibleBody from "../../../collapsible";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import ArtisButton from "../../../buttons/button";
import TransferDialog from "../../../dialogs/transfer-dialog";
import React from "react";
import { WorkService } from "../../../../api";

export default function Transfer(props) {
  const { inCertificates, setInCertificates, artis_code, ...other } = props;
  const [open, setOpen] = React.useState(false);

  const TransferCertificate = async () => {
    const response = await WorkService.transferCerticate({
      cert_id: inCertificates[0]?.id,
      on_transfer: false,
    });

    if (response?.result) {
      try {
        const certificateDetails = await WorkService.getCertificatesDetails(
          inCertificates[0]?.id,
          artis_code
        );
        // Make sure `response.result` is in the correct format
        setInCertificates(certificateDetails?.result);
      } catch (error) {
        console.log("Error fetching collection:", error);
      }
    }

    return response?.result ? true : false;
  };

  return (
    <CollapsibleBody
      expanded={other.openId === "tags-body"}
      id="tags-body"
      title={"Transfer"}
      icon={<MoveUpIcon />}
      handleClick={other.setOpenId}
      mainSx={{}}
    >
      <Box component="div" sx={{ p: "10px" }}>
        Here you can transfer who holds this certificate. The new holder’s name
        will appear on the certificate and your name will be removed. Each
        transfer is recorded on the blockchain, the history of these transfers
        (provenance) appears on the certificate.
        <br />
        <br /> <strong>Please note:</strong> Once this transfer is completed, it
        cannot be reversed. Should you want the certificate back, the new holder
        will need to transfer it back to you as a second transaction.
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <ArtisButton
              name="Begin Transfer"
              onClick={() => setOpen(true)}
              id="begin_transfer"
              sx={{
                fontSize: "1.5rem",
              }}
            />
          </Grid>
        </Grid>
        {inCertificates && inCertificates[0]?.transfer_code !== null ? (
          <>
            <Box component="p" sx={{ textAlign: "center" }}>
              There is an active transfer in progress.
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <ArtisButton
                  name="Cancel Transfer"
                  onClick={TransferCertificate}
                  id="cancel_transfer"
                  sx={{
                    fontSize: "1.5rem",
                  }}
                />
              </Grid>
            </Grid>
          </>
        ) : null}
      </Box>

      <TransferDialog
        open={open}
        setOpen={setOpen}
        inCertificates={inCertificates}
        setInCertificates={setInCertificates}
        artis_code={artis_code}
        onTransferRequestFail={props.onTransferRequestFail}
        cert_id={props.cert_id}
      />
    </CollapsibleBody>
  );
}
