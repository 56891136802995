import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import ArtisInput from '../inputs/textfield'
import ArtisButton from '../buttons/button'
import Loader from '../buttons/loader'
import UserService from "../../api/auth"
import { useUser } from "../../hooks/user"
import { FameUserItemWrapper } from './FameUserItem'
import FameModal from './FameModal'
import QRCode from "react-qr-code"
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { toast } from 'react-toastify'

const validationSchema = Yup.object().shape({
    inviteName: Yup.string().required(),
    inviteEmail: Yup.string().email().required(),
    inviteMessage: Yup.string(),
});

const Fame = ({
    theme,
    setIsPendingFame,
    isAddCommunityVisible,
    setIsAddCommunityVisible,
    senderProfileData
}) => {
    const auth = useUser()
    const [connections, setConnections] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedOrderItems, setSelectedOrderItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [maxOrder, setMaxOrder] = useState(0);
    const [totalConnections, setAllConnections] = useState([]);
    const [showCommunityInvitations, setShowCommunityInvitations] = useState(false);

    const getAllFameConnections = async () => {
        try {
            const { profile } = auth.user;
            const response = await UserService.getFameConnections()

            if (response.result) {
                if (response.result.pending_fame?.length > 0) {
                    setIsPendingFame(true)
                } else {
                    setIsPendingFame(false)
                }
                setShowCommunityInvitations(false);
                const verified_connections = []
                const unverified_connections = []

                response.result.all_fame?.forEach(fame => {
                    const customerUser = profile.user.id === fame?.sender[0].user_id ? fame?.invitee[0] : fame?.sender[0]
                    if (customerUser.is_kyced) {
                        verified_connections.push({ ...fame, user: customerUser, sender: profile.user.id === fame?.sender[0].user_id })
                    } else {
                        unverified_connections.push({ ...fame, user: customerUser, sender: profile.user.id === fame?.sender[0].user_id })
                    }
                    if (fame.status === 4 && profile.user.id === fame?.invitee[0].user_id) {
                        setShowCommunityInvitations(true);
                    }
                })

                const all_connections = [...verified_connections, ...unverified_connections];
                setAllConnections(all_connections);
                setConnections({
                    verified_connections,
                    unverified_connections,
                    connection_emails: response.result.connection_emails ? response.result.connection_emails : [],
                    pending_fame: response.result.pending_fame ? response.result.pending_fame : []
                })
            }

            const orderItems = []
            response.result?.verified_connections?.forEach(user => {
                if (user.order !== 0) {
                    orderItems.push(user.order.toString())
                }
            })
            setSelectedOrderItems(orderItems)
        } catch (error) {
            console.error("Failed to fetch fame connections:", error);
        }
    }

    useEffect(() => {
        getAllFameConnections()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const deleteConnection = async () => {
        try {
            const { unverified_connections, verified_connections } = connections
            await UserService.deleteFameConnection(selectedUser.id)
            if (!selectedUser.is_kyced) {
                const filtered = unverified_connections.filter(con => con.id !== selectedUser.id)
                setConnections({ ...connections, unverified_connections: filtered })
            } else {
                const filtered = verified_connections.filter(con => con.id !== selectedUser.id)
                const itemToDelete = verified_connections.find(item => item.id === selectedUser.id)
                if (itemToDelete.order !== 0) {
                    const copyOrder = [...selectedOrderItems].filter(item => item !== itemToDelete.order.toString())
                    setSelectedOrderItems(copyOrder)
                }
                setConnections({ ...connections, verified_connections: filtered })
            }
        } catch (error) {

        } finally {
            setIsModalVisible(false)
            setSelectedUser(null)
        }
    }

    const addToCommunity = async () => {
        try {
            await UserService.addToCommunity({
                sender_email: senderProfileData?.profile?.user?.email,
            })
        } catch (error) {
            console.error(error)
        } finally {
            setIsAddCommunityVisible(false)
        }
    }

    return (
        <Box
            sx={{
                flexGrow: 1,
                backgroundColor: theme.palette.primary,
                mt: "2%",
                overflow: "hidden",
                display: "grid",
                boxShadow: 2,
                backgroundSize: "cover",
                backgroundPosition: "0 400px",
                padding: 5,
            }}
        >

            {showCommunityInvitations &&
                <Box
                    sx={{
                        display: 'flex',
                        flexFlow: "row wrap",
                        backgroundColor: '#e8f5e9',
                        padding: "5px",
                        margin: "20px 0",
                        justifyContent: "center"
                    }}
                >
                    <Typography sx={{ color: 'teal', fontSize: "18px" }}>Accept or Decline Community Invitations</Typography>
                </Box>
            }

            {connections?.pending_fame?.length > 0 &&
                <>
                    <h3 color="blue" >Connection Requests</h3>
                    <p>Click on each name to view, then accept or decline</p>
                    {connections?.pending_fame?.map(pending => {
                        const customerUser = pending.sender[0]
                        const { profile } = auth.user
                        const name = customerUser?.first_name && customerUser?.last_name ?
                            `${customerUser?.first_name} ${customerUser?.last_name}` : pending.invitee_name
                        return (
                            <FameUserItemWrapper
                                user={{ ...customerUser }}
                                name={name}
                                shouldExpand={true}
                                userProfile={profile}
                                order={pending.order}
                                is_kyced={customerUser.is_kyced}
                                isPending={true}
                                fame={pending}
                                getAllFameConnections={getAllFameConnections}
                                fromFame={true}
                                fromEditOrDetail={false}
                            // endorsedBy={pending.endorsed_by}
                            />
                        )
                    })}
                    <br />
                    <br />
                </>
            }
            <Box sx={{ display: "flex", flexFlow: "column wrap" }}>
                <h3>Invite people to your creative community</h3>
                <Typography>Community members appear under your biography in all of your Artis.app public pages. You can notify members of new works and add them as collaborators when registering new works.
                    <br /><br />The value, prestige and protection of your works is built on the strength of your creative community. Invite people to your creative community now! <br />
                </Typography>
                
                <Box
                    sx={{
                        mt: 2,
                    }}
                >
                    <h6>INVITE BY SCANNING QR CODE</h6>
                    <Typography>Have people use their mobile phone's camera to scan this QR code and they will be invited.<br />
                    </Typography>

                    
                    <Box
                        sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 2,
                        }}
                    >
                        <QRCode
                            value={`https://artis.app/works?sender_id=${auth.user.profile.id}`}
                            size={128} // Adjust this size as needed for half the original size
                        />
                    </Box>
                    

                    <h6><br />INVITE BY TEXTING A LINK</h6>
                    
                    <Box component="p" fontSize="15px">
                        <em>Link:</em> {`https://artis.app/works?sender_id=${auth.user.profile.id}`}
                        <Tooltip title="Copy Link">
                            <IconButton
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        `https://artis.app/works?sender_id=${auth.user.profile.id}`
                                    );
                                    toast.success(
                                        "Link copied to clipboard!",
                                        {
                                            position: "bottom-center",
                                            autoClose: 2000,
                                            hideProgressBar: true,
                                        }
                                    );
                                }}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip><br />
                        Copy this link (click on the little double page icon), then paste it into mobile phone's SMS text, WhatsApp, or Messenger, etc. Then send it to their phone. When they click on the link, they'll be invited to Artis.app and your community.
                    </Box>
                </Box>
                <h6><br />INVITE BY EMAIL</h6>
                <Typography>You can have Artis.app send them an invite email. Just fill out the info below and press the Email Invitation button.
                </Typography>
                <Box sx={{ width: "100%" }}>
                    <Formik onSubmit={async (values, { resetForm }) => {
                        const addEmailConnection = () => {
                            const doesExist = connections.connection_emails.find(e => e === values.inviteEmail)
                            if (!doesExist) {
                                const copy_emails = [...connections.connection_emails, values.inviteEmail]
                                setConnections({ ...connections, connection_emails: copy_emails })
                            }
                        }
                        try {
                            setIsLoading(true)
                            const response = await UserService.sendFameInvitation({
                                invitee_name: values.inviteName,
                                invitee_email: values.inviteEmail.toLowerCase(),
                                invitee_message: values.inviteMessage
                            });

                            const { unverified_connections, verified_connections } = connections;

                            if (response.result) {
                                if (response.result.is_kyced) {
                                    const copy_verified = [...verified_connections, response.result]
                                    setConnections({ ...connections, verified_connections: copy_verified })
                                    addEmailConnection()
                                } else {
                                    const copy_unverified = [...unverified_connections, response.result]
                                    setConnections({ ...connections, unverified_connections: copy_unverified })
                                    addEmailConnection()
                                }
                                resetForm()
                            } else {
                                addEmailConnection()
                                resetForm()
                            }

                        } catch (error) {
                            console.error(error)
                        } finally {
                            setIsLoading(false)
                        }
                    }} validationSchema={validationSchema} initialValues={{ inviteName: "", inviteEmail: "", inviteMessage: "" }}>
                        {({ setFieldValue, values, handleSubmit, isValid, dirty }) => {
                            return (
                                <Form>
                                    <ArtisInput
                                        label="Their name"
                                        id="name"
                                        value={values.inviteName}
                                        onChange={(e) => setFieldValue("inviteName", e.target.value)}
                                    />
                                    <ArtisInput
                                        label="Their email"
                                        id="email"
                                        value={values.inviteEmail}
                                        onChange={(e) => setFieldValue("inviteEmail", e.target.value)}
                                    />
                                    <ArtisInput
                                        label="Personal message from you."
                                        id="message"
                                        value={values.inviteMessage}
                                        multiline
                                        onChange={(e) => setFieldValue("inviteMessage", e.target.value)}
                                    />

                                    {isLoading ? <Loader /> :
                                        <ArtisButton
                                            id="submit"
                                            onClick={handleSubmit}
                                            name="Email Invitation"
                                            disabled={!(isValid && dirty)}
                                            sx={{
                                                fontSize: "1.5rem",
                                                mb: 3,
                                            }}
                                        />
                                    }
                                </Form>
                            )
                        }}
                    </Formik>
                    <h6><br />EMAILED INVITATIONS</h6>
                    {connections?.connection_emails.length > 0 &&
                        
                        <Typography>Below are invitations you emailed. If someone hasn't responded or lost your invite, have Artis.app email them a new invite.</Typography>
                    }
                    <Box sx={{ marginBottom: 5 }}>{connections?.connection_emails.map((email, index) => {
                        const addComma = connections?.connection_emails.length !== index + 1 ? ',' : ''
                        return (
                            <span style={{ color: 'grey' }}>{email}{addComma} </span>
                        )
                    })}</Box>
                    <Box sx={{ display: "flex", flexFlow: "column wrap" }}>
                        <h3>Manage your creative community</h3>
                        <p>If both you and a member of your community verified your Artis.app accounts, an option will appear that allows you to professionally endorse them. If you do so, your name will appear in the 'Professionally Endorsed By' section of their public biography. </p>
                        {connections?.verified_connections?.length === 0 && connections?.unverified_connections?.length === 0 &&
                            <Typography fontSize="1.2rem"
                                component="h3"
                                color="black"
                                display="inline"
                            >
                                No connections yet
                            </Typography>
                        }
                        {connections?.verified_connections?.length > 0 &&
                            <>
                                {connections?.verified_connections?.map(fame => {
                                    const { profile } = auth.user
                                    const customerUser = fame.user
                                    const name = customerUser?.first_name && customerUser?.last_name ?
                                        `${customerUser?.first_name} ${customerUser?.last_name}` : fame.invitee_name
                                    return (
                                        <FameUserItemWrapper
                                            user={{ ...customerUser }}
                                            name={name}
                                            shouldExpand={true}
                                            order={fame.order}
                                            fame={fame}
                                            sender={fame.sender}
                                            status={fame.status}
                                            getAllFameConnections={getAllFameConnections}
                                            maxOrder={maxOrder}
                                            allConnections={connections?.verified_connections}
                                            setMaxOrder={setMaxOrder}
                                            userProfile={profile}
                                            is_kyced={customerUser.is_kyced}
                                            onDelete={() => {
                                                setSelectedUser({ name, id: fame.id, is_kyced: true })
                                                setIsModalVisible(true)
                                            }}
                                            fromFame={true}
                                            endorsedBy={fame.endorsed_by}
                                            totalConnections={totalConnections}
                                            fromEditOrDetail={false}
                                        />
                                    )
                                })}
                            </>
                        }
                        {connections?.unverified_connections?.length > 0 &&
                            <>
                                {connections?.unverified_connections?.map(fame => {
                                    const { profile } = auth.user
                                    const customerUser = fame.user
                                    const name = customerUser?.first_name && customerUser?.last_name ?
                                        `${customerUser?.first_name} ${customerUser?.last_name}` : fame.invitee_name
                                    return (
                                        <FameUserItemWrapper
                                            user={{ ...customerUser }}
                                            name={name}
                                            shouldExpand={true}
                                            userProfile={profile}
                                            order={fame.order}
                                            status={fame.status}
                                            sender={fame.sender}
                                            fame={fame}
                                            getAllFameConnections={getAllFameConnections}
                                            is_kyced={customerUser.is_kyced}
                                            onDelete={() => {
                                                setSelectedUser({ name, id: fame.id, is_kyced: false })
                                                setIsModalVisible(true)
                                            }}
                                            fromFame={true}
                                            totalConnections={totalConnections}
                                            fromEditOrDetail={false}
                                        />
                                    )
                                })}
                            </>
                        }
                    </Box>
                </Box>
                <br />
                <div>
                    <center>
                        <FameModal
                            isVisible={isModalVisible}
                            setIsVisible={setIsModalVisible}
                            handleConfirm={deleteConnection}
                            title={`Delete your creative community connection to ${selectedUser?.name}`}
                            actionButtonText="Yes, Delete"
                        />
                    </center>
                    <center>
                        <FameModal
                            isVisible={isAddCommunityVisible}
                            setIsVisible={setIsAddCommunityVisible}
                            handleConfirm={addToCommunity}
                            title={`Add ${senderProfileData?.profile?.first_name + " " + senderProfileData?.profile?.last_name} to your community?`}
                            actionButtonText="Yes"
                            isDeleteModal={false}
                        />
                    </center>
                </div>
            </Box>
            <Typography
                fontSize="0.6rem"
                gutterBottom
                width="100%"
                float="right"
                textAlign="right"
            >
                TAP ON NAME TO DISPLAY MORE INFORMATION
            </Typography>
            <Typography
                fontSize="0.6rem"
                gutterBottom
                width="100%"
                float="right"
                textAlign="right"
            >
                <img style={{ height: 10 }} alt="identity validated" src="/images/GoldWreath.jpg" /> = IDENTITY CONFIRMED WITH GOVERNMENT DOCUMENTS
            </Typography>

        </Box>
    )
}

export default Fame
