import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { Box, IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import ArtisButton from "../buttons/button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function TransferLink(props) {
  const { transferCode, setOpenTansferBy, setOpenLinkModal, openLinkModal } =
    props;

  const handleClose = () => {
    setOpenLinkModal(false);
  };
  const closeModal = () => {
    setOpenTansferBy(false);
    setOpenLinkModal(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openLinkModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          <IconButton
            aria-label="go-back"
            onClick={() => setOpenLinkModal(false)}
          >
            <ArrowBackIcon />
          </IconButton>
          <span
            style={{ marginLeft: "1rem", flexGrow: 1, textAlign: "center" }}
          >
            {"Copy and text the link below to transfer the certificate."}
          </span>
        </DialogTitle>
        
        <DialogContent sx={{ padding: "20px 24px 0px 24px" }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: "center" }}
          >
            <Box component="p" fontSize="15px">
              <em>Link:</em>{" "}
              {`https://artis.app/works?transfer_code=${transferCode.transfer_code}`}
              <Tooltip title="Copy Link">
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://artis.app/works?transfer_code=${transferCode.transfer_code}`
                    );
                    toast.success("Link copied to clipboard!", {
                      position: "bottom-center",
                      autoClose: 2000,
                      hideProgressBar: true,
                    });
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              <br />
            </Box>
          </DialogContentText>
          <ArtisButton
            name="Cancel"
            onClick={closeModal}
            id="Cancel"
            sx={{
              fontSize: "1.5rem",
            }}
            textColor="black"
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
